// Vendor files


require('./vendor/jquery-1.11.1.min');

var $ = window.jQuery = window.$ = require('./vendor/jquery-1.11.1.min');

var $$_ = window.$$_ = require('./shared/core'); 

require('./vendor/slick'); 

require('./vendor/parallax.min');

var Isotope = require('../_gulp/node_modules/isotope-layout');

// include your scripts here

// require('./shared/textarea-autosize'); 
// require('./shared/img'); 
// require('./shared/map'); 
// require('./shared/parallax'); 
// require('./shared/select'); 
// require('./shared/search'); 

// expose your functions to the global scope for testing
var mxm = {},
_viewing;

// init some things
$(function($){
	// $('.header').click(function(){
	// 	console.log('yeah!')
	// 	$body.toggleClass('sidebar_open')
	// });

	// console.log('yeah!');


	//  ad88888ba  88 88          88
	// d8"     "8b 88 ""          88
	// Y8,         88             88
	// `Y8aaaaa,   88 88  ,adPPYb,88  ,adPPYba, 8b,dPPYba,
	//   `"""""8b, 88 88 a8"    `Y88 a8P_____88 88P'   "Y8
	//         `8b 88 88 8b       88 8PP""""""" 88
	// Y8a     a8P 88 88 "8a,   ,d88 "8b,   ,aa 88
	//  "Y88888P"  88 88  `"8bbdP"Y8  `"Ybbd8"' 88
	
	var _slickSettings = {
	  dots: true,
	  infinite: true,
	},

	$header_nav = $('.header_nav'),
	shouldSwitch = false,

	$body = $('body'),
	_isIndex = $body.hasClass('page_index');


	if (window.location.hash && window.location.hash === '#waisenhausplatz'){
		console.log('YEß')
		$('.header_nav').find('a.current').removeClass('current').prev('a').addClass('current');
		shouldSwitch = true;
	}


	if (window.location.hash && window.location.hash === '#haendler'){
		console.log('YEßß')
		// $('.header_nav').find('a.current').removeClass('current').next('a').addClass('current');
		shouldSwitch = true;
	}

	// if (
	// 	window.location.hash && (
	// 		(window.location.hash === '#waisenhausplatz') ||
	// 		(window.location.hash === '#haendler') 
	// 	)
	// ){
	// 	console.log('IS ONE')
	// 	setTimeout(function(){
	// 		$body.addClass('ready');
	// 	}, 1000);
	// }


	if ($(window).width() > 768){
		window.onbeforeunload = function () {
		  window.scrollTo(0, 0);
		}
	}

	$('.header_logo').on('click', function(){
		console.log('yeah!')
		$(this).siblings('.header_nav').find('a').first().click();
	});

	$('.header_nav a').on('click', function(e){
		var $that = $(this);

		$body.removeClass('headerOpen');
		$('.header_hamburger').removeClass('fa-times').addClass('fa-bars');

		if ($that.hasClass('internal') && _isIndex){
			e.preventDefault();
			window.location.hash = $that.attr('href').split('#')[1];

			var _distance = $(window.location.hash).offset().top;

			window.scrollTo(0, _distance);
			$('.vendor_content[data-slug="' + _viewing + '"] .vendor_hero_back').click();
		}
	})

	$('.header_hamburger').on('click', function(){
		$body.toggleClass('headerOpen');

		if ($body.hasClass('headerOpen')){
			$(this).removeClass('fa-bars').addClass('fa-times')
		}else{
			$(this).removeClass('fa-times').addClass('fa-bars')
		}
	});


	if (_isIndex){

		// $body.addClass('ready');

		if (!shouldSwitch){
			$header_nav.find('a').removeClass('current');
		}


		setTimeout(function(){
			$body.addClass('ready');
		}, 200);

		var _height = $(window).outerHeight(),
		$waisenhausplatz = $('#waisenhausplatz'),
		$haendler = $('#haendler');


  	$(window).scroll(function(e){
  		var $that = $(this),
  		_scrollTop = $that.scrollTop();

  		if (_scrollTop > ((_height / 2)) - 100){
	  		$body.addClass('show_header');
  		}else{
	  		$body.removeClass('show_header');  			
  		}

  	// 	console.log(
  	// 		_scrollTop,
  	// 		$waisenhausplatz.offset().top,
  	// 		_scrollTop > $waisenhausplatz.offset().top
			// )

			if (_scrollTop > ($waisenhausplatz.offset().top - 100) ){
				$header_nav.find('a').eq(0).addClass('current').siblings().removeClass('current')
			}
			if (_scrollTop > ($haendler.offset().top - 100) ){
				$header_nav.find('a').eq(1).addClass('current').siblings().removeClass('current')
			}
  	});


  	var parallax = document.querySelectorAll(".parallax"),
  		parallax_slow = document.querySelectorAll(".parallax_slow"),
      speed = 0.5;

  	  window.onscroll = function(){

  	    [].slice.call(parallax).forEach(function(el,i){

  	      var windowYOffset = window.pageYOffset,
  	          elBackgrounPos = "50% " + (windowYOffset * speed) + "px";

  	      el.style.backgroundPosition = elBackgrounPos;

  	    });

	    	var _teaserCalculation = (
	    		$('.index_hero_teaser').offset().top - 
	    		(
  	    		( $(window).height() - $('.index_hero_teaser').height() ) / 2
	    		)
	    	);

  	    if (
  	    	window.pageYOffset > _teaserCalculation
	    	){
	  	    [].slice.call(parallax_slow).forEach(function(el,i){

	  	    	var card_travel = window.pageYOffset - _teaserCalculation

	  	      var windowYOffset = window.pageYOffset,
	  	          elTransform = 'translateY(' + (card_travel * .5) + 'px)';

	  	      el.style.transform = elTransform;

	  	    });
  	    	
  	    }else{
  	    	[].slice.call(parallax_slow).forEach(function(el,i){
    		    el.style.transform = 'translateY(0px)';
	    		});
  	    }

  	  };

	}


	// 88b           d88                       88
	// 888b         d888                       88                    ,d
	// 88`8b       d8'88                       88                    88
	// 88 `8b     d8' 88 ,adPPYYba, 8b,dPPYba, 88   ,d8  ,adPPYba, MM88MMM
	// 88  `8b   d8'  88 ""     `Y8 88P'   "Y8 88 ,a8"  a8P_____88   88
	// 88   `8b d8'   88 ,adPPPPP88 88         8888[    8PP"""""""   88
	// 88    `888'    88 88,    ,88 88         88`"Yba, "8b,   ,aa   88,
	// 88     `8'     88 `"8bbdP"Y8 88         88   `Y8a `"Ybbd8"'   "Y888
	
	$('.market_section .market_title').on('click', function(){
		var $that = $(this);

		var $ms = $that.parents('.market_section');

		$ms.toggleClass('closed');

		$ms.siblings('.market_section').addClass('closed');

		// $that[0].scrollIntoView({block: "start", inline: "nearest"});

		$$_.scrollToHere($that);

		if ($ms.hasClass('closed')){
			$ms.find('.slider').slick('unslick');
		}else{
			$ms.find('.slider').slick(_slickSettings);
		}
		
	});

	$('.market_text a').filter(function() {
	    return this.hostname != window.location.hostname;
	}).attr('target', '_blank');




	//   ,ad8888ba,             88          88
	//  d8"'    `"8b            ""          88
	// d8'                                  88
	// 88            8b,dPPYba, 88  ,adPPYb,88
	// 88      88888 88P'   "Y8 88 a8"    `Y88
	// Y8,        88 88         88 8b       88
	//  Y8a.    .a88 88         88 "8a,   ,d88
	//   `"Y88888P"  88         88  `"8bbdP"Y8
	
	

	

	

	var loadGrid = function(content){

		// if (typeof content !== 'undefined'){
			// $vendor_grid.find('.vendor_grid_interior_floater').append(content);

			$vendor_grid.find('.vendor_content').each(function(){
				var $that = $(this);

				var vendorName = $that.find('.vendor_title h1').text();

				$that.prev('.vendor_tile').find('.tile_text_title').html(vendorName);
			})
		// }


		var isotope_options = {
			sortBy : 'random',
			itemSelector: '.tile',
			layoutMode: 'fitRows'
		}

		var iso = new Isotope( '.vendor_grid_interior_floater', isotope_options);

		$('.vendor_grid_filter_option').on('click', function(e){
			var $that = $(this);

			var value = $that.data('value');

			if (value === '*'){
				value = '*'
				// window.location.hash = '';
			}else{
				value = '.day_' + $that.data('value');
				// window.location.hash = $that.data('value');
			}

			console.log(value)

			$that.siblings().removeClass('selected');

			$that.addClass('selected');

			iso.arrange({
				filter: value
			})
			iso.arrange();

		});

		// $('.header_nav').on('click', '.internal', function(e){
		// 	console.log('yeah!')
			
		// });

		$('.vendor_tile').on('click', function(){
			var $that = $(this);

			var _slug = $that.data('target'),
			$content = $('.vendor_content[data-slug="' + _slug + '"]');
			console.log(_slug);

			_viewing = _slug;

			$body.addClass('showing_vendor');

			window.location.hash = _slug;

			heroImageUrl = $content.find('.vendor_hero_background').data('src');

			$content.find('.vendor_hero_background').css('background-image', 'url(' + heroImageUrl + ')');

			$content.find('.vendor_gallery_image').each(function(){
				var $$that = $(this),
				_url = $$that.data('src');

				console.log(_url)

				$$that.css('background-image', 'url(' + _url + ')');
			});

			$content.find('.vendor_gallery').slick(_slickSettings);

			$content.find('.vendor_gallery_image').on('click', function(){
				$content.find('.vendor_gallery').slick('slickNext');
			});

			$content.removeClass('closed');

			setTimeout(function(){
				$(window).resize();
			}, 100)

		});

		$('.vendor_hero_back, .vendor_footer').on('click', function(){

			var $that = $(this),
			$vendor_content = $that.parents('.vendor_content');
			
			_viewing = '';

			$vendor_content.scrollTop(0);

			$vendor_content.addClass('closed');

			window.location.hash = '';

			$body.removeClass('showing_vendor');

			$vendor_content.find('.vendor_gallery').slick('unslick');

			document.getElementById('haendler').scrollIntoView();

			setTimeout(function(){
				$vendor_content.scrollTop(0);
			}, 500);
		});

		if (window.location.hash && window.location.hash !== '#haendler' && window.location.hash !== '#waisenhausplatz'){
			console.log('yeah!', $('.vendor_tile[data-target="' + window.location.hash.split('#')[1] + '"]'))

			$('.vendor_tile[data-target="' + window.location.hash.split('#')[1] + '"]').click();

			$body.addClass('show_header');

			_viewing = window.location.hash.split('#')[1];
			// isotope_options['filter'] = '.day_' + window.location.hash.split('#')[1];

			// console.log($('.vendor_grid_filter div[data-value="' + window.location.hash.split('#')[1] + '"]'))

			// $('.vendor_grid_filter div[data-value="' + window.location.hash.split('#')[1] + '"]').addClass('selected')
		}

		setTimeout(function(){
			if (typeof failsafe !== 'undefined'){
				clearInterval(failsafe);
			}
		}, 100);


		// things to do on completion
	};

	var $vendor_grid = $('.vendor_grid');

	if ($vendor_grid.length){
	// 	$.ajax({
	// 		url: 'http://' + window.location.host + "/marit/haendler.php",
	// 	}).done( function(_content){
			// loadGrid(_content)
			loadGrid()
	// 	})
	}











	//////////////
});




// template for new JS files, a la browserify
$(function($){

});



















